import React, { FunctionComponent, useContext, useState } from 'react';
import { TextField } from '../../../../../design-system';

import { FormValidatorDef } from '../../../../../types/form-validator';
import { BrandingContext } from '../../../Branding/BrandingContext';

interface LogoLinkCardProps {
    className?: string;
}

const LogoLinkCard: FunctionComponent<LogoLinkCardProps> = (props) => {
    const { className } = props;

    const { apiDocsBrandingState, updateLogoUri } = useContext(BrandingContext);

    const [formValidator, setFormValidator] = useState<FormValidatorDef>({
        isSubmitted: false,
        errors: {},
    });

    const setLogoURL = (url: string) => {
        updateLogoUri(url);
    };

    return (
        <TextField
            className={className}
            placeholder="Logo Link"
            name="logoUrl"
            value={apiDocsBrandingState?.logos?.logoUrl}
            onChange={(e) => setLogoURL(e.target.value)}
            validation={{
                key: 'logoUrl',
                url: true,
            }}
            form={{ state: formValidator, setState: setFormValidator }}
        />
    );
};

export default LogoLinkCard;
