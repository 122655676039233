import React, { FunctionComponent, useContext } from 'react';
import './Logo.scss';

import { BrandingContext } from '../../Branding/BrandingContext';

const Logo: FunctionComponent = () => {
    const { apiDocsBrandingState } = useContext(BrandingContext);

    return (
        <div className="flex w-full space-around homepage-logo-wrapper">
            {apiDocsBrandingState.logos?.lightTheme?.logo?.uri && (
                <div className="relative flex items-center">
                    <div className="mr-2">
                        <img src={apiDocsBrandingState.logos?.lightTheme?.logo?.uri} alt="" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Logo;
