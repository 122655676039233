import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { SelectField, Button } from '../../../design-system';
import { DownloadIcon } from '../../../icons/Ui/DownloadIcon';
import { SidebarIllustration } from '../../../icons/Ui/SidebarIllustration';
import { CustomContentContext } from '../../CustomContent/CustomContentContext';
import { TocType, ToCItemsType } from '../../CustomContent/CustomContentTypes';
import {
    useApiSpecExport,
    ApiSpecExportProviderValue,
} from '../ApiSpecExport/ApiSpecExportContext';
import { useApiDocsBranding } from '../Branding/BrandingContext';
import { ApiDocsBrandingContextValue } from '../Branding/BrandingTypes';
import { PlatformTemplateType, TemplateType } from '../Platforms/PlatformConstants';
import { usePlatforms, PlatformsProviderValue } from '../Platforms/PlatformsContext';
import { platformOptions, PlatformOptionDef } from './ApiDocsPreviewConstants';
import { getAllGeneratedContentList } from '../../CustomContent/CustomContentConstant';
import { SidebarIllustration1 } from '../../../icons/Ui/SidebarIllustration1';
import { SidebarIllustration2 } from '../../../icons/Ui/SidebarIllustration2';
import Spinner from '../../../design-system/Spinner/spinner';

const Sidebar: FunctionComponent = () => {
    const {
        apiDocsBrandingState,
        uiState: brandingUiState,
    } = useApiDocsBranding() as ApiDocsBrandingContextValue;
    const {
        platformsState,
        platformsState: { sdkPlatforms, http },
        uiState: platformUiState,
    } = usePlatforms() as PlatformsProviderValue;
    const { apiSpecExportState } = useApiSpecExport() as ApiSpecExportProviderValue;
    const { tableOfContents, uiState: tocUiState } = useContext(CustomContentContext);

    const activePlatformOptions = platformOptions.filter((platformOption: PlatformOptionDef) => {
        if (platformOption.value === 'http_curl_v1') {
            return http.enabled && platformOption;
        } else {
            const currentPlatform = sdkPlatforms[platformOption.value as PlatformTemplateType];
            return currentPlatform && currentPlatform.platformEnabled && platformOption;
        }
    });

    const [selectedPlatform, setSelectedPlatform] = useState<TemplateType>(
        activePlatformOptions[0]?.value
    );

    useEffect(() => {
        setSelectedPlatform(activePlatformOptions[0]?.value);
    }, [platformsState]);

    const renderList = (list: TocType, isParent: boolean) => {
        return (
            <ul className="pl-2">
                {list.map((item, i) => {
                    return (
                        <span key={(item as any).group + i.toString()}>
                            {'group' in item && (
                                <li className={`${isParent && 'pt-2'} text-13`}>
                                    <span className="block pt-2  font-medium capitalize">
                                        {' '}
                                        {item.group}
                                    </span>
                                    <ul className="pl-2">{renderListItem(item.items)}</ul>
                                </li>
                            )}

                            {'generate' in item &&
                                item.from === getAllGeneratedContentList()[0].from &&
                                renderGeneratedToC(item.generate)}
                        </span>
                    );
                })}
                {isParent && renderGeneratedToC(getAllGeneratedContentList()[1].generate, 2)}
                {isParent && renderGeneratedToC(getAllGeneratedContentList()[2].generate, 2)}
            </ul>
        );
    };

    const renderGeneratedToC = (label: string, illustrationType: 1 | 2 = 1) => {
        return (
            <li className="pt-2 text-13">
                <span className="block mt-2 mb-2  font-medium">{label}</span>
                {illustrationType === 1 && (
                    <div className="mt-4 mb-2 ml-2">
                        <SidebarIllustration1 />
                    </div>
                )}
                {illustrationType === 2 && (
                    <div className="mt-4 mb-2 ml-2">
                        <SidebarIllustration2 />
                    </div>
                )}
            </li>
        );
    };

    const renderListItem = (listItem: ToCItemsType) => {
        return listItem.map((item, index) => {
            return (
                <li key={index}>
                    {'file' in item && <span className="block py-2">{item.page}</span>}
                    {'group' in item && renderList([item], false)}
                </li>
            );
        });
    };

    return (
        <div className="sidebar-wrapper">
            {platformUiState.isLoading && brandingUiState.isLoading ? (
                <Spinner size="m" className="mx-auto " />
            ) : (
                <>
                    <div
                        className={
                            'flex justify-center mt-4' +
                            (selectedPlatform === 'http_curl_v1' ? '' : ' flex-col')
                        }
                    >
                        <SelectField
                            className={
                                'mx-2' + (selectedPlatform === 'http_curl_v1' ? ' w-1/2' : '')
                            }
                            id=""
                            name=""
                            options={activePlatformOptions}
                            value={selectedPlatform}
                            onChange={(e): void =>
                                setSelectedPlatform(e.target.value as TemplateType)
                            }
                        />
                        <div
                            className={
                                'flex justify-center' +
                                (selectedPlatform === 'http_curl_v1' ? ' w-1/2 mr-2' : ' mt-4 mx-2')
                            }
                        >
                            {selectedPlatform !== 'http_curl_v1' &&
                                sdkPlatforms[selectedPlatform as PlatformTemplateType]
                                    ?.sdkEnabled && (
                                    <Button
                                        fill="primary"
                                        text="Get SDK"
                                        className="flex w-full mr-1"
                                        style={{
                                            backgroundColor:
                                                apiDocsBrandingState.colors.light.primaryColor,
                                        }}
                                    />
                                )}

                            {apiSpecExportState.enabled && (
                                <Button
                                    fill="default"
                                    text="Api Spec"
                                    className={
                                        'flex w-full' +
                                        (selectedPlatform === 'http_curl_v1' ? '' : ' ml-1')
                                    }
                                    icon={<DownloadIcon fill="#24313E" />}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}

            {/* <div className="px-2 mt-6">
            <SidebarIllustration />
        </div> */}

            <div className="px-2 mt-4 overflow-auto custom-guides custom-scroll">
                {tocUiState.isLoading ? (
                    <Spinner size="m" className="mx-auto " />
                ) : (
                    renderList(tableOfContents, true)
                )}
            </div>
        </div>
    );
};

export default Sidebar;
