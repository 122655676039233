import {
    actionType,
    CHANGE_DEFAULT_FONTS_TYPE,
    CHANGE_ADVANCED_FONT_TYPE,
    CHANGE_COLOR_TYPE,
    CHANGE_LOGO_TYPE,
    ApiDocsBrandingStateDef,
    CHANGE_DEFAULT_FONTS,
    CHANGE_ADVANCED_FONT,
    CHANGE_COLOR,
    CHANGE_LOGO,
    CHANGE_FAVICON,
    UPDATE_LOGO_URI,
    UPDATE_LOGO_URI_TYPE,
    UPDATE_FAVICON_URI,
    CLEAR_LOGO_FAVICON_FILES,
    REMOVE_LOGO,
    REMOVE_FAVICON,
    RESET_ADVANCED_FONT,
    CHANGE_BASE_THEME,
    CHANGE_DEFAULT_MODE,
    CHANGE_THEME_MODE,
    LogoBrandingType,
    REMOVE_LOGO_TYPE,
} from './BrandingTypes';
import { UPDATE_STATE } from '../ApiDocsHeader/ApiDocsHeaderReducer';
import { brandingInitialState } from './BrandingInitialState';

export const brandingReducer = (
    state: ApiDocsBrandingStateDef,
    action: actionType
): ApiDocsBrandingStateDef => {
    if (action.type === UPDATE_STATE) {
        return {
            ...action.payload,
        };
    }

    if (action.type === CHANGE_DEFAULT_FONTS) {
        const currentAction = action as CHANGE_DEFAULT_FONTS_TYPE;
        const { apiDocsBrandingState } = brandingInitialState;

        if (currentAction.payload.sectionKey === 'heading') {
            return {
                ...state,
                typography: {
                    ...state.typography,
                    heading: {
                        fontFamily: currentAction.payload.font,
                        h1: {
                            ...apiDocsBrandingState.typography.heading.h1,
                            fontFamily: currentAction.payload.font,
                        },
                        h2: {
                            ...apiDocsBrandingState.typography.heading.h2,
                            fontFamily: currentAction.payload.font,
                        },
                        h3: {
                            ...apiDocsBrandingState.typography.heading.h3,
                            fontFamily: currentAction.payload.font,
                        },
                        h4: {
                            ...apiDocsBrandingState.typography.heading.h4,
                            fontFamily: currentAction.payload.font,
                        },
                        h5: {
                            ...apiDocsBrandingState.typography.heading.h5,
                            fontFamily: currentAction.payload.font,
                        },
                        h6: {
                            ...apiDocsBrandingState.typography.heading.h6,
                            fontFamily: currentAction.payload.font,
                        },
                    },
                },
            };
        } else if (currentAction.payload.sectionKey === 'body') {
            return {
                ...state,
                typography: {
                    ...state.typography,
                    body: {
                        fontFamily: currentAction.payload.font,
                        text1: {
                            ...apiDocsBrandingState.typography.body.text1,
                            fontFamily: currentAction.payload.font,
                        },
                        text2: {
                            ...apiDocsBrandingState.typography.body.text2,
                            fontFamily: currentAction.payload.font,
                        },
                        text3: {
                            ...apiDocsBrandingState.typography.body.text3,
                            fontFamily: currentAction.payload.font,
                        },
                    },
                },
            };
        } else if (currentAction.payload.sectionKey === 'code') {
            return {
                ...state,
                typography: {
                    ...state.typography,
                    code: {
                        fontFamily: currentAction.payload.font,
                        blockCode: {
                            ...apiDocsBrandingState.typography.code.blockCode,
                            fontFamily: currentAction.payload.font,
                        },
                        inlineCode: {
                            ...apiDocsBrandingState.typography.code.inlineCode,
                            fontFamily: currentAction.payload.font,
                        },
                    },
                },
            };
        }
    }

    if (action.type === CHANGE_ADVANCED_FONT) {
        const currentAction = action as CHANGE_ADVANCED_FONT_TYPE;

        const payload = currentAction.payload;
        const identifier = payload.identifier;

        if (identifier.sectionKey === 'heading') {
            return {
                ...state,
                typography: {
                    ...state.typography,
                    heading: {
                        ...state.typography.heading,
                        fontFamily: '',
                        [identifier.subSectionKey]: {
                            ...state.typography.heading[identifier.subSectionKey],
                            [payload.property]: payload.value,
                        },
                    },
                },
            };
        } else if (identifier.sectionKey === 'body') {
            return {
                ...state,
                typography: {
                    ...state.typography,
                    body: {
                        ...state.typography.body,
                        fontFamily: '',
                        [identifier.subSectionKey]: {
                            ...state.typography.body[identifier.subSectionKey],
                            [payload.property]: payload.value,
                        },
                    },
                },
            };
        } else if (identifier.sectionKey === 'code') {
            return {
                ...state,
                typography: {
                    ...state.typography,
                    code: {
                        ...state.typography.code,
                        fontFamily: '',
                        [identifier.subSectionKey]: {
                            ...state.typography.code[identifier.subSectionKey],
                            [payload.property]: payload.value,
                        },
                    },
                },
            };
        }
    }

    if (action.type === RESET_ADVANCED_FONT) {
        const payload = action.payload;
        const sectionKey = payload.sectionKey;

        return {
            ...state,
            typography: {
                ...state.typography,
                [sectionKey]: {
                    ...payload.typogrophy[sectionKey],
                    fontFamily: '',
                },
            },
        };
    }

    if (action.type === CHANGE_COLOR) {
        const currentAction = action as CHANGE_COLOR_TYPE;
        const themeMode = currentAction.payload.themeMode;
        const colorTheme = currentAction.payload.color;

        return {
            ...state,
            colors: {
                ...state.colors,
                [themeMode]: { ...state.colors[themeMode], ...colorTheme },
            },
        };
    }

    if (action.type === CHANGE_BASE_THEME) {
        return {
            ...state,
            baseTheme: action.payload.baseTheme,
        };
    }

    if (action.type === CHANGE_DEFAULT_MODE) {
        return {
            ...state,
            defaultMode: action.payload.defaultMode,
        };
    }
    if (action.type === CHANGE_THEME_MODE) {
        return {
            ...state,
            disableSwitch: action.payload.disableSwitch,
        };
    }

    if (action.type === CHANGE_LOGO) {
        const currentAction = action.payload as LogoBrandingType;
        const themeMode = currentAction.themeMode;

        return {
            ...state,
            logos: {
                ...state.logos,
                [themeMode]: {
                    logo: { file: currentAction.file, delete: false, uri: currentAction.uri },
                },
            },
        };
    }

    if (action.type === CHANGE_FAVICON) {
        const currentAction = action as CHANGE_LOGO_TYPE;
        return {
            ...state,
            favicon: {
                ...state.favicon,
                file: currentAction.payload.file,
                delete: false,
            },
        };
    }

    if (action.type === UPDATE_LOGO_URI) {
        const currentAction = action as UPDATE_LOGO_URI_TYPE;
        return {
            ...state,
            logos: {
                ...state.logos,
                logoUrl: currentAction.payload.uri,
            },
        };
    }

    if (action.type === UPDATE_FAVICON_URI) {
        const currentAction = action as UPDATE_LOGO_URI_TYPE;
        return {
            ...state,
            favicon: {
                ...state.favicon,
                uri: currentAction.payload.uri,
            },
        };
    }

    if (action.type === CLEAR_LOGO_FAVICON_FILES) {
        return {
            ...state,
            logo: {
                uri: state.logo.uri,
                file: null,
                delete: false,
            },
            favicon: {
                uri: state.favicon.uri,
                file: null,
                delete: false,
            },
        };
    }

    if (action.type === REMOVE_LOGO) {
        const currentAction = action as REMOVE_LOGO_TYPE;
        const themeMode = currentAction.payload.themeMode;

        return {
            ...state,
            logos: {
                ...state.logos,
                [themeMode]: {
                    logo: { file: null, delete: true, uri: null, id: null },
                },
            },
        };
    }

    if (action.type === REMOVE_FAVICON) {
        return {
            ...state,
            favicon: {
                uri: null,
                file: null,
                delete: true,
            },
        };
    }
    return state;
};
