import { TypographySectionKeyType } from './Typography/TypographyConstant';
import {
    TypographyElementIdentifierType,
    TypographyElementProperty,
    TypographyType,
} from './Typography/TypographySection/TypographySectionConstant';
import { ColorsType, ThemeColor, ThemeMode } from './Colors/ColorsContant';
import { LogoType, FileType } from './Logo/LogoConstant';
import { ReactNode } from 'react';
import { UiStateDef } from '../../../types/ui-state';
import { FormValidatorDef } from '../../../types/form-validator';

export const UPDATE_STATE = 'UPDATE_STATE';
export const CHANGE_DEFAULT_FONTS = 'CHANGE_DEFAULT_FONTS';
export const CHANGE_ADVANCED_FONT = 'CHANGE_ADVANCED_FONT';
export const RESET_ADVANCED_FONT = 'RESET_ADVANCED_FONT';
export const CHANGE_COLOR = 'CHANGE_COLOR';
export const CHANGE_LOGO = 'CHANGE_LOGO';
export const CHANGE_FAVICON = 'CHANGE_FAVICON';
export const UPDATE_LOGO_URI = 'UPDATE_LOGO_URI';
export const UPDATE_FAVICON_URI = 'UPDATE_FAVICON_URI';
export const CLEAR_LOGO_FAVICON_FILES = 'CLEAR_LOGO_FAVICON_FILES';
export const REMOVE_LOGO = 'REMOVE_LOGO';
export const REMOVE_FAVICON = 'REMOVE_FAVICON';

export const CHANGE_BASE_THEME = 'CHANGE_BASE_THEME';
export const CHANGE_DEFAULT_MODE = 'CHANGE_DEFAULT_MODE';
export const CHANGE_THEME_MODE = 'CHANGE_THEME_MODE';

export type UPDATE_STATE_TYPE = {
    type: 'UPDATE_STATE';
    payload: ApiDocsBrandingStateDef;
};

export type CHANGE_DEFAULT_FONTS_TYPE = {
    type: 'CHANGE_DEFAULT_FONTS';
    payload: {
        sectionKey: TypographySectionKeyType;
        font: string;
    };
};

export type CHANGE_ADVANCED_FONT_TYPE = {
    type: 'CHANGE_ADVANCED_FONT';
    payload: {
        identifier: TypographyElementIdentifierType;
        property: TypographyElementProperty;
        value: string;
    };
};
export type RESET_ADVANCED_FONT_TYPE = {
    type: 'RESET_ADVANCED_FONT';
    payload: {
        typogrophy: TypographyType;
        sectionKey: TypographySectionKeyType;
    };
};

export type CHANGE_COLOR_TYPE = {
    type: 'CHANGE_COLOR';
    payload: {
        color: ColorsType;
        themeMode: ThemeMode; 
    };
};


export type LogoThemeType = 'lightTheme' | 'darkTheme'

export type REMOVE_LOGO_TYPE = {
    type: "REMOVE_LOGO";
    payload: {
        themeMode: LogoThemeType; 
    };
}

export type CHANGE_BASE_THEME_TYPE = {
    type: typeof CHANGE_BASE_THEME;
    payload: {
        baseTheme: BaseTheme;
    };
};

export type CHANGE_DEFAULT_MODE_TYPE = {
    type: typeof CHANGE_DEFAULT_MODE;
    payload: {
        defaultMode: DefaultMode;
    };
};

export type CHANGE_THEME_MODE_TYPE = {
    type: typeof CHANGE_THEME_MODE;
    payload: {
        disableSwitch: boolean;
    };
};

export type LogoBrandingType =  {
    file: FileType;
    themeMode: 'lightTheme' | 'darkTheme',
    uri: string
}

type FavBrandingType = {
    file: FileType;
}

export type CHANGE_LOGO_TYPE = {
    type: 'CHANGE_LOGO' | 'CHANGE_FAVICON';
    payload: LogoBrandingType | FavBrandingType;
};

export type UPDATE_LOGO_URI_TYPE = {
    type: 'UPDATE_LOGO_URI' | 'UPDATE_FAVICON_URI';
    payload: {
        uri: string;
    };
};

export type GENERAL_ACTION_TYPE = {
    type: 'CLEAR_LOGO_FAVICON_FILES' | 'REMOVE_LOGO' | 'REMOVE_FAVICON';
};

export type actionType =
    | UPDATE_STATE_TYPE
    | CHANGE_DEFAULT_FONTS_TYPE
    | CHANGE_ADVANCED_FONT_TYPE
    | CHANGE_COLOR_TYPE
    | CHANGE_LOGO_TYPE
    | UPDATE_LOGO_URI_TYPE
    | GENERAL_ACTION_TYPE
    | RESET_ADVANCED_FONT_TYPE
    | CHANGE_BASE_THEME_TYPE
    | CHANGE_DEFAULT_MODE_TYPE
    | CHANGE_THEME_MODE_TYPE
    | REMOVE_LOGO_TYPE

export enum BaseTheme {
    Standard = 'standard',
}

export enum DefaultMode {
    Light = 'light',
    Dark = 'dark',
    System = 'system',
}

export type ApiDocsBrandingStateDef = {
    typography: TypographyType;
    colors: ThemeColor;
    logo: LogoType;
    favicon: LogoType;
    fontSources: string[];
    baseTheme: BaseTheme;
    defaultMode: DefaultMode;
    disableSwitch: boolean;
    logos: {
        lightTheme: { logo: LogoType };
        darkTheme: { logo: LogoType };
        logoUrl: string
    };
};

export interface FontListDef {
    text: string;
    value: string;
}

export interface ApiDocsBrandingProviderProps {
    children: ReactNode;
}

export type ApiDocsBrandingContextValue = {
    apiDocsBrandingState: ApiDocsBrandingStateDef;
    uiState: UiStateDef;
    form: {
        state: FormValidatorDef;
        setState: React.Dispatch<React.SetStateAction<FormValidatorDef>>;
    };
    changeDefaultFont: (sectionKey: TypographySectionKeyType, font: string) => void;
    changeAdvancedFont: (
        identifier: TypographyElementIdentifierType,
        property: TypographyElementProperty,
        value: string
    ) => void;
    restAdvancedFont: (typogrophy: TypographyType, sectionKey: TypographySectionKeyType) => void;

    changeColor: (color: ColorsType, themeMode: ThemeMode) => void;
    changeLogo: (file: FileType, themeMode: LogoThemeType, uri: string) => void;
    changeFavicon: (file: FileType) => void;
    updateLogoUri: (uri: string) => void;
    updateFaviconUri: (uri: string) => void;
    clearLogoFaviconFiles: () => void;
    removeLogo: ( themeMode: LogoThemeType) => void;
    removeFavicon: () => void;
    updateUiState: (newState: UiStateDef) => void;
    changeBaseTheme: (baseTheme: BaseTheme) => void;
    changeDefaultMode: (defaultMode: DefaultMode) => void;
    changeThemeMode: (disableSwitch: boolean) => void;
};
