import React, { FunctionComponent, useContext } from 'react';
import { FieldBox, FieldGroupCard, SelectField, ToggleSwitch } from '../../../../design-system';
import ColorSelector from '../../../../design-system/ColorSelector/ColorSelector';
import { BrandingContext } from '../BrandingContext';
import Tooltip from '../../../../design-system/Tooltip/Tooltip';
import { ColorsType, ThemeMode } from './ColorsContant';
import { BaseTheme, DefaultMode } from '../BrandingTypes';

const defaulModeSelect = [
    { text: 'Light', value: 'light' },
    { text: 'Dark', value: 'dark' },
    { text: 'System', value: 'system' },
];

const baseThemeSelect = [{ text: 'Standard', value: 'standard' }];

const Colors: FunctionComponent = () => {
    const {
        apiDocsBrandingState,
        changeColor,
        changeBaseTheme,
        changeDefaultMode,
        changeThemeMode,
    } = useContext(BrandingContext);

    // const changeAccent = (text: string, isValid: boolean): void => {
    //     changeColor(text, 'accent');
    // };

    const changeThemeColor = (
        color: string,
        themeMode: ThemeMode,
        type: keyof ColorsType
    ): void => {
        const theme = {
            [type]: color,
        } as ColorsType;

        changeColor(theme, themeMode);
    };

    return (
        <div>
            <div className="flex flex-row">
                <FieldBox label="Base Theme" className="mb-5">
                    <SelectField
                        className={'w-64'}
                        id=""
                        name=""
                        options={baseThemeSelect}
                        value={apiDocsBrandingState.baseTheme}
                        onChange={(e): void => changeBaseTheme(e.target.value as BaseTheme)}
                    />
                </FieldBox>
                <FieldBox label="Default Mode" className="mb-5">
                    <SelectField
                        className={'w-64'}
                        id=""
                        name="Default Mode"
                        options={defaulModeSelect}
                        value={apiDocsBrandingState.defaultMode}
                        onChange={(e): void => changeDefaultMode(e.target.value as DefaultMode)}
                    />
                </FieldBox>
            </div>
            <FieldBox
                label="Disable Mode Selector"
                type="inline"
                className="mb-5"
                hasFullWidth={false}
            >
                <div style={{ paddingLeft: '58px' }}>
                    <Tooltip message="Toggle Visibility for Mode Selector." position="right">
                        <ToggleSwitch
                            checked={apiDocsBrandingState.disableSwitch}
                            onChange={(e) => {
                                const toggleType = e.target.checked;
                                changeThemeMode(toggleType);
                            }}
                        />
                    </Tooltip>
                </div>
            </FieldBox>

            <FieldBox label="Colors" className=" flex flex-row">
                <FieldGroupCard className="flex flex-col">
                    <FieldBox label="Primary Color" className="flex flex-row px-6 pt-6">
                        <div className="flex flex-row w-full bg-white p-6 card-wrapper rounded-6">
                            <FieldBox label="Light">
                                <ColorSelector
                                    color={apiDocsBrandingState.colors.light.primaryColor}
                                    onChange={(color) => {
                                        changeThemeColor(color, 'light', 'primaryColor');
                                    }}
                                />
                            </FieldBox>
                            <FieldBox label="Dark">
                                <ColorSelector
                                    color={apiDocsBrandingState.colors.dark.primaryColor}
                                    onChange={(color) => {
                                        changeThemeColor(color, 'dark', 'primaryColor');
                                    }}
                                />
                            </FieldBox>
                        </div>
                    </FieldBox>
                    <FieldBox label="Link Color" className="flex flex-row p-6 ">
                        <div className="flex flex-row w-full bg-white p-6 card-wrapper rounded-6">
                            <FieldBox label="Light">
                                <ColorSelector
                                    color={apiDocsBrandingState.colors.light.linkColor}
                                    onChange={(color) => {
                                        changeThemeColor(color, 'light', 'linkColor');
                                    }}
                                />
                            </FieldBox>
                            <FieldBox label="Dark">
                                <ColorSelector
                                    color={apiDocsBrandingState.colors.dark.linkColor}
                                    onChange={(color) => {
                                        changeThemeColor(color, 'dark', 'linkColor');
                                    }}
                                />
                            </FieldBox>
                        </div>
                    </FieldBox>
                </FieldGroupCard>
            </FieldBox>
        </div>
    );
};

export default Colors;
