import React, { FunctionComponent, useContext } from 'react';
import { FieldBox, Button, FieldGroupCard } from '../../../../design-system';
import { BrandingContext } from '../BrandingContext';
import { FileType } from './LogoConstant';
import './Logo.scss';
import { TrashIcon } from '../../../../icons/Ui/TrashIcon';
import FileSelector from '../../../../design-system/FileSelector/FileSelector';
import LogoLinkCard from '../../HomePage/Logo/LogoLinkCard/LogoLinkCard';

const Logo: FunctionComponent = () => {
    const {
        apiDocsBrandingState,
        changeLogo,
        changeFavicon,
        updateFaviconUri,
        removeLogo,
        removeFavicon,
    } = useContext(BrandingContext);

    const _handleLogoSelected = (files: FileType, type: 'lightTheme' | 'darkTheme') => {
        if (files) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = function () {
                const logoURI = reader.result as string;
                changeLogo(files, type, logoURI);
            };
        }
    };

    const _handleFaviconSelected = (files: FileType) => {
        if (files) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = function (e) {
                updateFaviconUri(reader.result as string);
                changeFavicon(files);
            };
        }
    };

    return (
        <div className="flex flex-col logo-wrapper ">
            <FieldBox className="mt-0 mb-5" label="Favicon">
                <>
                    {apiDocsBrandingState.favicon?.uri ? (
                        <div className="relative flex justify-center mb-5 overflow-hidden border border-solid image-preview border-goose-500 rounded-12 align-center">
                            <Button
                                className="absolute top-0 right-0 mt-3 mr-3"
                                text=""
                                fill="default"
                                icon={<TrashIcon width="12" height="12" fill="#455666" />}
                                onClick={removeFavicon}
                            />
                            <img
                                className="self-center"
                                alt=""
                                src={apiDocsBrandingState.favicon.uri}
                            />
                        </div>
                    ) : (
                        <div className="flex justify-center branding-logo image-preview rounded-12">
                            <FileSelector
                                className="file-button"
                                text="+ ADD LOGO"
                                fill="link"
                                file={apiDocsBrandingState.favicon.file}
                                onChange={_handleFaviconSelected}
                            />
                        </div>
                    )}
                </>
            </FieldBox>
            <FieldBox className="mr-8" label="Logo">
                <>
                    <FieldGroupCard className="flex flex-row">
                        <FieldBox label="Light" className="flex flex-row p-6 ">
                            <div className="flex flex-col justify-between">
                                {apiDocsBrandingState.logos?.lightTheme?.logo?.uri ? (
                                    <div className="relative flex justify-center mb-5 overflow-hidden border border-solid image-preview border-goose-500 rounded-12 align-center">
                                        <Button
                                            className="absolute top-0 right-0 mt-3 mr-3"
                                            text=""
                                            fill="default"
                                            icon={
                                                <TrashIcon width="12" height="12" fill="#455666" />
                                            }
                                            onClick={() => removeLogo('lightTheme')}
                                        />
                                        {apiDocsBrandingState.logos?.lightTheme?.logo?.uri && (
                                            <img
                                                className="self-center"
                                                src={apiDocsBrandingState.logos.lightTheme.logo.uri}
                                                alt=""
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex justify-center branding-logo image-preview rounded-12">
                                        <FileSelector
                                            className="file-button"
                                            text="+ ADD LOGO"
                                            fill="link"
                                            file={
                                                apiDocsBrandingState.logos?.lightTheme?.logo?.file
                                            }
                                            onChange={(file) =>
                                                _handleLogoSelected(file, 'lightTheme')
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </FieldBox>
                        <FieldBox label="Dark" className="flex flex-row p-6 ">
                            <div className="flex flex-col justify-between">
                                {apiDocsBrandingState.logos?.darkTheme?.logo?.uri ? (
                                    <div className="relative flex justify-center mb-5 overflow-hidden border border-solid image-preview border-goose-500 rounded-12 align-center">
                                        <Button
                                            className="absolute top-0 right-0 mt-3 mr-3"
                                            text=""
                                            fill="default"
                                            icon={
                                                <TrashIcon width="12" height="12" fill="#455666" />
                                            }
                                            onClick={() => removeLogo('darkTheme')}
                                        />
                                        {apiDocsBrandingState.logos?.darkTheme?.logo?.uri && (
                                            <img
                                                className="self-center"
                                                src={apiDocsBrandingState.logos.darkTheme.logo.uri}
                                                alt=""
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex justify-center branding-logo image-preview rounded-12 ">
                                        <FileSelector
                                            className="file-button"
                                            text="+ ADD LOGO"
                                            fill="link"
                                            file={apiDocsBrandingState.logos?.darkTheme?.logo?.file}
                                            onChange={(file) =>
                                                _handleLogoSelected(file, 'darkTheme')
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </FieldBox>
                    </FieldGroupCard>
                    <FieldBox label="Redirect Link" className="justify-between">
                        <LogoLinkCard />
                    </FieldBox>
                </>
            </FieldBox>
        </div>
    );
};

export default Logo;
