/* eslint-disable @typescript-eslint/no-empty-function */
import { ApiDocsBrandingContextValue, BaseTheme, DefaultMode } from './BrandingTypes';
import { ThemeColor } from './Colors/ColorsContant';
import { LogoType } from './Logo/LogoConstant';

export const headingDefaultFont = 'Rubik, sans-serif';
export const bodyDefaultFont = 'Rubik, sans-serif';
export const codeDefaultFont = 'Courier Prime, monospace';

const defaultTypographyObject = {
    heading: {
        fontFamily: headingDefaultFont,
        h1: {
            fontFamily: headingDefaultFont,
            fontSize: '27px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '0.9',
        },
        h2: {
            fontFamily: headingDefaultFont,
            fontSize: '24px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '0.82',
        },
        h3: {
            fontFamily: headingDefaultFont,
            fontSize: '21.36px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '1',
        },
        h4: {
            fontFamily: headingDefaultFont,
            fontSize: '18px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '1.17',
        },
        h5: {
            fontFamily: headingDefaultFont,
            fontSize: '16px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '1.26',
        },
        h6: {
            fontFamily: headingDefaultFont,
            fontSize: '15px',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '1.35',
        },
    },
    body: {
        fontFamily: bodyDefaultFont,
        text1: {
            fontFamily: bodyDefaultFont,
            fontSize: '15px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.78',
        },
        text2: {
            fontFamily: bodyDefaultFont,
            fontSize: '13.33px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.5',
        },
        text3: {
            fontFamily: bodyDefaultFont,
            fontSize: '11.85px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.66',
        },
    },
    code: {
        fontFamily: codeDefaultFont,
        blockCode: {
            fontFamily: codeDefaultFont,
            fontSize: '15px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.66',
        },
        inlineCode: {
            fontFamily: codeDefaultFont,
            fontSize: '15px',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.66',
        },
    },
};

const defaultColor: ThemeColor = {
    light: {
        accent: '#d94338',
        primaryColor: '#0C7FF2',
        linkColor: '#004AD7',
    },
    dark: {
        accent: '#7CBAF8',
        primaryColor: '#7CBAF8',
        linkColor: '#A3D1FF',
    },
};

const defaultLogo: LogoType = { id: null, uri: '', file: null, delete: null };
const defaultFavicon: LogoType = { id: null, uri: '', file: null, delete: null };

const defaultLogos = {
    lightTheme: {
        logo: defaultLogo,
    },
    darkTheme: {
        logo: defaultLogo,
    },
    logoUrl: '',
};

export const brandingInitialState: ApiDocsBrandingContextValue = {
    apiDocsBrandingState: {
        typography: defaultTypographyObject,
        colors: defaultColor,
        logo: defaultLogo,
        favicon: defaultFavicon,
        fontSources: [],
        baseTheme: BaseTheme.Standard,
        defaultMode: DefaultMode.Light,
        disableSwitch: true,
        logos: defaultLogos,
    },
    uiState: {
        isLoading: true,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    },
    changeDefaultFont: () => {},
    changeAdvancedFont: () => {},
    changeColor: () => {},
    changeLogo: () => {},
    changeFavicon: () => {},
    form: {
        state: { isSubmitted: false, errors: {} },
        setState: () => {},
    },
    restAdvancedFont: () => {},
    updateLogoUri: () => {},
    updateFaviconUri: () => {},
    clearLogoFaviconFiles: () => {},
    removeLogo: () => {},
    removeFavicon: () => {},
    updateUiState: () => {},
    changeBaseTheme: () => {},
    changeDefaultMode: () => {},
    changeThemeMode: () => {},
};
