import { getHeaders, fetcher } from './ApiClient';
import { ApiDocsBrandingStateDef } from '../components/api-docs/Branding/BrandingTypes';

const uri = `api-groups/{apiGroupId}/draft-portal-settings/theme`;

const getApiDocsBranding = (): Promise<ApiDocsBrandingStateDef> => {
    return fetcher<ApiDocsBrandingStateDef>(uri, 'GET', getHeaders());
};

const updateApiDocsBranding = (body: ApiDocsBrandingStateDef) => {
    return fetcher<ApiDocsBrandingStateDef>(uri, 'PUT', getHeaders(), body);
};

const updateApiDocsBrandingLogo = (formData: FormData, logoPath?: string) => {
    return fetcher<FormData>(uri + logoPath || '/logo', 'PUT', getHeaders(true), formData, true);
};

const updateApiDocsBrandingFavicon = (formData: FormData) => {
    return fetcher<FormData>(uri + '/favicon', 'PUT', getHeaders(true), formData, true);
};

const removeApiDocsBrandingLogo = (logoPath?: string) => {
    return fetcher<null>(uri + logoPath || '/logo', 'DELETE', getHeaders());
};

const removeApiDocsBrandingFavicon = () => {
    return fetcher<null>(uri + '/favicon', 'DELETE', getHeaders());
};

export {
    getApiDocsBranding,
    updateApiDocsBranding,
    updateApiDocsBrandingLogo,
    updateApiDocsBrandingFavicon,
    removeApiDocsBrandingLogo,
    removeApiDocsBrandingFavicon,
};
