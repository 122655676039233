import React, { FunctionComponent } from 'react';
import { InfoIcon } from '../../icons/Ui/InfoIcon';
import Tooltip from '../Tooltip/Tooltip';
import { InfoToolTipType } from '../../types/design-system';
import './field-box.scss';

interface FeildBoxProps {
    label: string;
    type?: 'inline';
    className?: string;
    children: JSX.Element;
    tooltip?: InfoToolTipType;
    hasFullWidth?: boolean;
}

const FieldBox: FunctionComponent<FeildBoxProps> = (props) => {
    const { label, type, className, children, tooltip, hasFullWidth = true } = props;
    const fieldBoxType = type === 'inline' ? 'flex-row' : 'flex-col';
    const fullWidth = hasFullWidth || hasFullWidth === undefined ? 'w-full' : '';
    return (
        <div className={`w-full field-box flex ${fieldBoxType} ${className}`}>
            <div className={`flex flex-row ${fullWidth} field-box`}>
                <label className="mb-2 font-medium text-14">{label}</label>
                {tooltip?.enabled && tooltip.message !== '' && (
                    <Tooltip message={tooltip.message} event="hover">
                        <InfoIcon
                            fill="#24313E"
                            width="12px"
                            height="12px"
                            className="ml-2 mt-info-icon"
                        ></InfoIcon>
                    </Tooltip>
                )}
            </div>
            {children}
        </div>
    );
};

export default FieldBox;
